.workHero {
  margin-top: 160px;
  margin-bottom: 80px;
  width: 100%;
  display: flex;
  padding-left: 0px;
  flex-direction: column;
  align-items: flex-start;
}

.workHero .subtext {
  font-family: 'PT Serif', serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 96px;
  color: var(--secondary-text);
}

.workHero .header {
  display: flex;
  gap: 20px;
}

.workHero .header h1, .typewriterText {
  font-family: 'PT Serif', serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  color: var(--primary-text);
  text-wrap: nowrap;
}

.typewriterText {
  color: var(--primary);
  
}

.Typewriter {
  width: 320.05px;
}

.gap-80 {
  gap: 80px;
}

.projects {
  max-width: 1032px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 40px;
}

.project {
  width: 496px;
  height: 404px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 40px;
  cursor: pointer;
  -webkit-transition: box-shadow 200ms cubic-bezier(.215, .61, .355, 1), -webkit-transform 200ms ease;
  transition: box-shadow 200ms cubic-bezier(.215, .61, .355, 1), -webkit-transform 200ms ease;
  transition: box-shadow 200ms cubic-bezier(.215, .61, .355, 1), transform 200ms ease;
  transition: box-shadow 200ms cubic-bezier(.215, .61, .355, 1), transform 200ms ease, -webkit-transform 200ms ease;
}

.project:hover {
  box-shadow: 1px 2px 20px 1px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.project .thumbnail {
  width: 496px;
}

.project .details {
  display: flex;
  width: 496px;
  padding: 22px 24px;
  height: 73px;
  justify-content: space-between;
}

.project .title {
  font-family: Karla, sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: var(--primary-text);

  text-align: left;
}

.project .tags {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
}

.project .tag {
  border: 1px solid var(--secondary-text);
  border-radius: 4px;
  padding: 2px 8px;

  font-family: Karla, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-text);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  transition: 0.5s;
}

.project .tag.pressable:hover{
  border: 1px solid var(--primary-text);
  color: var(--primary-text);
  cursor: pointer;
  transition: 0.5s;
}

.verticalAlign {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 1032px;
  overflow-x: visible;
}