/* Project Hero */
.projectHero {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.projectHero .details {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 170px;
}

.projectHero .title {
  font-family: 'PT Serif', serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  color: var(--primary-text);
  text-wrap: wrap;

}

.projectHero .description {
  font-family: Karla, sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: var(--secondary-text);
  text-wrap: wrap;
}

.projectHero img {
  height: 100vh;
  z-index: -1;
}

/* Main Content */
.projectContent {
  max-width: 1040px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 80px;
}

.projectContent h1 {
  font-family: Karla, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: var(--primary-text);

  width: auto;
  max-width: 752px;
}

.projectContent p, .listtext, .projectContent li {
  
  font-family: Karla, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: var(--secondary-text);

  width: 752px;
}


/* Side Title Component */
.sideTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 26px;
  color: #434343;
}

.sideTitle .left {
  width: 250px;
}

.sideTitle .bar {
  width: 68px;
  height: 5px;
  background-color: #6177EC;
  margin-bottom: 12px;
}

.sideTitle .content {
  font-family: 'Inter';
  font-weight: 200;
  font-size: 16px;
  width: 750px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
}

.sideTitle .content img {
  width: 100%;
}

.sideTitle .content h1 {
  font-family: Karla, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 1px;
  color: var(--primary-text);
  text-align: left;
  align-self: flex-start;
}

.sideTitle .content h2 {
  font-family: Karla, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 1px;
    color: var(--primary-text);
    text-align: left;
    align-self: flex-start;
    margin-bottom: -20px;
}

.sideTitle .overrideBoundary {
  margin-left: -274px;
}

/* Card Component */
.card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 24px;

  box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.05);
  padding: 32px;
}

.card div {
  width: 320px;
}

.card .section .header {
  font-family: Karla, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--secondary-text);
  letter-spacing: 2.1px;
  margin-bottom: 0px;
}

.card .section .text {
  font-family: Karla, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  color: var(--secondary-text);
  margin-bottom: 0px;
}

.card p {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 200;
  color: #434343;
  margin-bottom: 0px;
  line-height: 30px;
}

/* Gallery Component */
.gallery
{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  width: 100%;
}

.gallery .images {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 24px;
}

.gallery .images .image {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:center;
  gap: 24px
}

.gallery .images .image h1 {
  font-size: 16px;
  font-weight: 700;
  font-family: Karla, sans-serif;
  letter-spacing: 3.2px;
  line-height: 30px;
  width: auto;
}

.gallery .images .image p {
  font-size: 16px;
  font-weight: 400;
  font-family: Karla, sans-serif;
  line-height: 24px;
  color: var(--primary-text);
  width: auto;
}

.gallery .images .image img {
  width: 353px;
  margin: 0px;
}

.gallery .caption {
  font-family: Karla, sans-serif;
  font-size: 16px;
  font-weight: 200;
  line-height: 30px;
  width: auto;
}


/* Override Boundary Component */
.overrideBoundary {
  width: 100vw;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
}

.overrideBoundary .aloneImage{
  max-width: 1175px;
  margin: 64px 0px;
}

.overrideBoundary .gallery .images .image img {
  width: auto;
}

/* Subpages Component */
.subpages {
  width: 976px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
}

.subpages .subpage {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: flex-start;
  align-items: center;
}


.subpages .nav {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  background-color: #F1EFF5;
}

.subpages .nav .button {
  cursor: pointer;
  width: 100%;
  height: 48px;
  background-color:#F1EFF5;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 200;
  text-align: center;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subpages .nav .button.selected {
  background-color: #D9D6E2;
  border: 1px solid #AFAFD1;
}

.subpages .nav .button.first {
  border-radius: 10px 0px 0px 10px;
}
.subpages .nav .button.last {
  border-radius: 0px 10px 10px 0px;
}

/* Footer Component */
.projectFooter {
  margin-bottom: 0px;
  position: relative; 
  cursor: pointer;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: hidden;
}

.projectFooter .image{
  position: absolute;
  width: 100%;
  height: 600px;
  background-size: 100vw;
  z-index: -1;
}

.projectFooter .text {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.projectFooter .texts {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.projectFooter .details {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 400px;
}

.projectFooter .details h1 {
  width: auto;
  font-family: Karla, sans-serif;
  font-size: 32px;
  font-weight: 700;
  color: #FFFFFF;
}

.projectFooter .details p {
  width: auto;
  font-family: Karla, sans-serif;
  font-size: 20px;
  color: #ffffff;
}

.projectFooter .next {
  font-family: Karla, sans-serif;
  font-size: 40px;
  color: #FFFFFF;
  margin-right: 80px;
}

/* Container Component */
.container {
  display: flex;
  flex-direction:row ;
  gap: 24px;
}

/* Container Component */
.list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  list-style:disc;
  text-align: start;
  font-family: Karla, sans-serif;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.list li {
  margin-left:40px
}

.list h1 {
  margin-bottom: 35px;
}

.content .list h1 {
  line-height: normal;
}





